<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size:16px">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item>职业测评</el-breadcrumb-item>
        <el-breadcrumb-item>情绪智力程度测评</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="banner">
      <img style="width: 100%" src="@/assets/img/ceshi.jpg" alt />
    </div>
    <div v-if="!show">
      <div class="title">欢迎使用“情绪智力程度测评”！</div>
      <div class="f16">
        <p class="f20 mb10">正式测评前请认真阅读以下指导语：</p>
        <p>1. 本测评总共{{ len }}题，建议测评时长不超过 15 分钟；</p>
        <p>
          2.
          本测评全部为单项选择题，所有答案无对错好坏之分，请选择最符合自身情况的选项。难以抉择的选项建议第一感觉选择，不宜作过多思考；
        </p>
        <p>3. 测评过程中请确保环境安静舒适，不被打扰；</p>
        <p>
          4.
          测评结果将作为专业选择的重要参考依据，关乎个人前途，请务必认真完成。
        </p>
        <p>
          5. 霍兰德职业适应性测验(The Self-Directed
          Search，简称SDS)由美国著名职业指导专家
          Ｊ.霍兰德（ＨＯＬＬＡＮＤ）编制。在几十年间经过一百多次大规模的实验研究，形成了人格类型与职业类型的学说和测验。
          该测验能帮助被试者发现和确定自己的职业兴趣和能力专长,
          从而科学地做出求职择业。
        </p>
      </div>
      <span class="btn" @click="start">开始测评</span>
    </div>

    <div v-else>
      <div class="title">
        <el-row>
          <el-col :span="6">情绪智力程度测评</el-col>
          <el-col :span="3" :offset="15">
            <span class="f16">用时：{{ timeStr }}</span>
          </el-col>
        </el-row>
      </div>
      <div class="f16">
        <div v-for="(item, i) in list" :key="i" style="padding: 0 30px">
          <div v-if="curt == i">
            <p class="num f20">
              <span>{{ curt + 1 }}</span>/{{ len }}
            </p>
            <p class="question f26">{{ i + 1 }}、{{ item.Q }}</p>
            <div class="answers">
              <span class="answer" @click="doClick(i, item.A1)">{{
      item.A1 | str
    }}</span>
              <span class="answer" @click="doClick(i, item.A2)">{{
      item.A2 | str
    }}</span>
            </div>
          </div>
        </div>
        <el-progress :percentage="num" status="success" style="margin-top: 20px" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Test",
  props: ["type"],
  filters: {
    str(e) {
      let str = e.split("-");
      return str[0];
    },
  },
  data() {
    return {
      num: 0,
      show: false,
      list: [],
      len: 0,
      curt: 0,
      answers: {},
      time: 0,
      timeStr: "00分00秒",
      timer: null,
      add: true,
    };
  },
  computed: { ...mapState(["userInfo"]) },
  mounted() {
    this.$fecth
      .get("Appraisal/getAppraisal", {
        params: {
          url: "http://www.apesk.com/lee/json/eq/index_hr_m_json.asp",
          hr_email: "18537129969",
          checkcode: "51ECS7OIFOZAOGM3ZK",
        },
      })
      .then((res) => {
        this.list = res;
        this.len = res.length;
      });
  },
  methods: {
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    //倒计时
    start() {
      this.show = true;
      if (this.timer) return false;
      this.timer = setInterval(() => {
        this.time++;
        if (this.time > 59) {
          const s = this.checkTime(this.time % 60);
          const m = this.checkTime(Math.floor(this.time / 60));
          this.timeStr = m + "分" + s + "秒";
        } else {
          const num = this.checkTime(this.time);
          this.timeStr = "00分" + num + "秒";
        }
      }, 1000);
    },

    doClick(i, val) {
      // 判断男女
      let str = "";
      if (val == "男") {
        str = "male";
      } else if (val == "女") {
        str = "female";
      } else {
        str = val.split("-")[1];
      }

      // 生成表单
      this.answers["eq-answer" + (i + 1)] = str;
      if (i + 1 >= this.list.length && this.add) {
        this.add = false;
        clearInterval(this.timer);
        (this.answers.hr_email = "18537129969"),
          (this.answers.test_name = this.userInfo.user_name || "游客"),
          (this.answers.test_email = this.userInfo.mobile),
          (this.answers.liangbiao = "eq"),
          (this.answers.checkcode = "51ECS7OIFOZAOGM3ZK"),
          (this.timer = null);
        this.$fecth
          .post("Appraisal/getUrls", {
            url: "http://www.apesk.com/lee/json/eq/submit_conn_eq_m_json.asp",
            ParamName: "AnswerData",
            name: "情绪智力程度测评",
            AnswerData: JSON.stringify(this.answers),
          })
          .then(() => {
            this.add = true;
            this.$router.push("/user/testReport");
          });
      } else {
        this.curt = i + 1;
        this.num = (this.curt / this.len) * 100;
      }
    },
  },
};
</script>
<style lang="less">
// .el-breadcrumb {
//   font-size: 16px;
// }</style>

<style lang="less" scoped>
.page {
  min-height: 1000px;

  .breadcrumb {
    font-size: 16px !important;
    padding: 30px 0;
  }

  .title {
    color: #333333;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 2px solid #f2f2f2;
  }

  .f26 {
    font-size: 16px;
  }

  .f16 {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666666;

    p {
      margin-top: 10px;
    }
  }

  .f20 {
    font-size: 16px;
  }

  .btn {
    display: inline-block;
    background: #1787e0;
    height: 40px;
    width: 160px;
    line-height: 40px;
    margin-left: 1000px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    margin-top: 30px;
  }

  .answers {
    margin-top: 40px;

    .answer {
      display: inline-block;
      padding: 10px 40px;
      border: 2px solid #f2f2f2;
      border-radius: 6px;
      color: #454545;
      font-size: 20px;
      margin-right: 30px;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}
</style>
